
<div class="file-list">
    <div v-for="(file, index) in files" :key="index" class="file-item">
        <div class="file-icon">
            <span class="glyphicon" :class="{
                'glyphicon-file': !isImage(file),
                'glyphicon-picture': isImage(file)
            }"></span>
        </div>
        <div class="file-name">
            {{ file.name }}
        </div>
    </div>
</div>
